<template>
  <v-card v-if="typeOfUser === 'Free'">
      <v-card-text>
          <br>
          <br>
          <br>
          <div class="text-h5 text-center font-weight-bold" align-center>
                  This feature is available on paid version
          </div>
          <br>
          <br>
      </v-card-text>
    </v-card>
    <BotFlow :botid="botid" v-else></BotFlow>
</template>

<script>
import BotFlow from './components/BotFlow.vue';
export default {
  name: 'financialclose_automation_borrowing_bots',
  // props: ['props'],
  data: function () {
    return {
      botid: "6474cf6a1117a96cfd35795d",
      typeOfUser: "Paid",
    }
  },
  components: {
    BotFlow
  },
  created() {
    this.refreshData();
    this.init();
  },
  activated() {
    this.refreshData();
    this.init();
  },
  methods: {
    init() {
      if (this.$store.state.subscriptions.financialclose.plan_detail.name) {
        this.typeOfUser = this.$store.state.subscriptions.financialclose.plan_detail.name || "Paid";
      }
    },
    refreshData() {
    },
  },
  watch: {
  }
}
</script>
